import axios from 'axios';
const getWebViewEvent = async () => {
  return await axios.post('/api/mobile/getWebViewEvent');
};

const selectExternalEvent = async () => {
  return await axios.post('/api/mobile/selectExternalEvent');
};
const updateEventClickCount = async eventId => {
  return await axios.post('/api/mobile/updateEventClickCount', { eventId });
};

export { getWebViewEvent, selectExternalEvent, updateEventClickCount };
